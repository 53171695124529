<template>
  <div class="row center main-bg">
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />

    <!-- <v-row  style="float:right;">
      <div>
        <v-icon color="closeButton" @click="cancelOrder()">
          mdi-close-circle
        </v-icon>
      </div>
    </v-row> -->

    <v-btn
      color="secondary"
      dark
      @click="cancelOrder()"
      class="cancel-btn-group font-weight-bold text-h6 black--text"
      style="margin-top: 0px; border-radius: 0px 0px 10px 10px"
    >
      <v-icon color="black"> mdi-store </v-icon>

      Cancel
    </v-btn>
    <div class="product-details white-bg">
      <img
        v-if="singleItem.itemPic"
        class="product-img"
        :src="$url + singleItem.itemPic"
        alt=""
      />
      <v-img
        v-if="!singleItem.itemPic"
        class="no-product-img white--text align-end"
        gradient="rgba(248,162,41,.5), rgba(0,0,0,.2)"
      >
        <div style="margin-top: -50px; text-align: center">
          <v-icon class="text-h1" color="white"> mdi-image </v-icon>
        </div>
      </v-img>

      <div class="product-name-price">
        <h1 class="product-title">
          {{ singleItem.productName }}
        </h1>
        <h1
          class="product-price"
          v-if="
            singleItem.retailPrice &&
            (!singleItem.productSizes.length > 0 || itemSize)
          "
        >
          <!-- <v-icon class="text-h2 mt-n1" color="black">
            mdi-currency-usd
          </v-icon> -->
          <span v-if="!singleItem.isQuickMenu">
            $ {{ formatPrice(singleItem.retailPrice) }}</span
          >

          <span v-if="singleItem.isQuickMenu">
            $ {{ formatPrice(singleItem.discountPrice) }}</span
          >
        </h1>
      </div>
      <p class="product-description">
        {{ singleItem.productDescription }}
      </p>
      <div class="product-qty-price">
        <!-- <div style="display:flex" class="">
          <v-hover v-slot="{ hover }">
            <v-btn
              :style="
                hover
                  ? ' transform: scale(1.3);'
                  : ' min-width: 30px; width: 30px'
              "
              :class="{ 'on-hover': hover }"
              color="secondary2"
              tile
              dark
              small
              style=" min-width: 30px; width: 30px"
              @click="decreaseCount"
            >
              <v-icon style="font-size:20px" class="black--text">
                mdi-minus
              </v-icon>
            </v-btn>
          </v-hover>
        </div> -->
        <h2 class="product-qty">QTY: {{ count }}</h2>
        <!-- <div style="display:flex" class="">
          <v-hover v-slot="{ hover }">
            <v-btn
              :style="
                hover
                  ? ' transform: scale(1.3);'
                  : ' min-width: 30px; width: 30px'
              "
              :class="{ 'on-hover': hover }"
              color="primary"
              tile
              dark
              small
              style=" min-width: 30px; width: 30px"
              @click="increaseCount"
            >
              <v-icon style="font-size:20px" class="black--text">
                mdi-plus
              </v-icon>
            </v-btn>
          </v-hover>
        </div> -->

        <h2
          class="total-price"
          v-if="
            !productCost && (!singleItem.productSizes.length > 0 || itemSize)
          "
        >
          Total:
          <span v-if="!singleItem.isQuickMenu">
            $ {{ formatPrice(singleItem.retailPrice) }}</span
          >

          <span v-if="singleItem.isQuickMenu">
            $ {{ formatPrice(singleItem.discountPrice) }}</span
          >
        </h2>
        <h2 class="total-price" v-if="productCost">
          Total: ${{ formatPrice(productCost) }}
        </h2>
      </div>
      <div class="btn-group">
        <v-btn
          class="btn text-h5"
          color="primary"
          dark
          @click="addToCart()"
          style="border-radius: 5px"
        >
          UPDATE CART
        </v-btn>
        <v-btn
          class="btn text-h5"
          color="secondary2"
          dark
          @click="cancelOrder()"
          style="border-radius: 5px"
        >
          CANCEL
        </v-btn>
      </div>
    </div>
    <div class="product-options">
      <!-- <v-btn
        color="secondary"
        dark
        @click="cancelOrder()"
        class="btn-group font-weight-bold text-h6 black--text"
        style=" margin-top: 20px; margin-bottom: -30px; border-radius: 0px 0px 10px 10px;"
      >
        <v-icon color="black">
          mdi-store
        </v-icon>

        Cancel
      </v-btn> -->

      <div v-if="singleItem.productSizes.length > 0" class="options">
        <h1 class="option-title">SELECT SIZE</h1>
        <div class="size-btn-group">
          <div
            class="btn secondary-bg"
            v-for="size in singleItem.productSizes"
            :key="size.sizeId"
            @click="sizeClicked(size)"
          >
            <h2 class="center-img-card-title">
              {{ size.size.sizeName }}
            </h2>
            <h2 class="center-img-card-price">
              $ {{ formatPrice(size.price) }}
            </h2>

            <!-- <img
              class="full-img-card-img"
              :src="$url + size.image"
              alt=""
            /> -->
            <div v-if="itemSize == size.sizeId">
              <v-icon style="font-size: 30px" color="black">
                mdi-checkbox-marked
              </v-icon>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="addons in singleItem.productAddons"
        :key="addons.addonId"
        class="options"
      >
        <h1 class="option-title">
          SELECT ADDON :
          <span class="text-uppercase"> {{ addons.addon.addonName }} </span>
        </h1>
        <div class="toppings-list">
          <div
            class="center-img-card secondary-bg"
            v-for="addonItem in addons.addon.addonItems"
            :key="addonItem.addonItemId"
            @click="addOnItemClicked(addonItem)"
          >
            <h2 class="center-img-card-title">
              {{ addonItem.addonItemName }}
            </h2>
            <img
              class="full-img-card-img"
              :src="$url + addonItem.image"
              alt=""
            />
            <h2 class="center-img-card-price">
              $ {{ formatPrice(addonItem.unitPrice) }}
            </h2>

            <div
              v-if="itemAddon.includes(addonItem.addonItemId)"
              style="background-color: #e0e0e0"
            >
              <v-icon style="font-size: 30px" color="black">
                mdi-checkbox-marked
              </v-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="options">
        <h1 class="option-title">INSTRUCTIONS</h1>
        <div class="size-btn-group">
          <div
            class="btn secondary-bg"
            v-for="instruction in singleItem.productInstructions"
            :key="instruction.instructionId"
            @click="instructionClicked(instruction)"
          >
            <h2 class="center-img-card-title">
              {{ instruction.instruction.note }}
            </h2>
            <div
              v-if="instructions.includes(instruction.instructionId)"
              style="background-color: #e0e0e0"
            >
              <v-icon style="font-size: 30px" color="black">
                mdi-checkbox-marked
              </v-icon>
            </div>
          </div>
          <div class="btn secondary-bg">
            <v-col col="12" class="mt-3">
              <v-text-field
                @change="addAdditionalIns"
                v-model="additionalInstruction"
                label="Any Other Instructions ?"
                clearable
              />
            </v-col>
          </div>
        </div>
        {{ instructionsName.toString()
        }}<span v-if="instructionsName.length > 0">,</span>
        <span v-if="additionalInstruction">{{ additionalInstruction }} </span>
      </div>

      <div
        v-for="options in singleItem.productOptions"
        :key="options.optionId"
        class="options"
      >
        <h1 class="option-title">
          SELECT OPTION :
          <span class="text-uppercase">{{ options.option.optionName }} </span>
        </h1>
        <div class="item-option-list">
          <div
            class="center-img-card secondary-bg"
            v-for="optionItem in options.option.optionItems"
            :key="optionItem.optionItemId"
            @click="optionItemClicked(optionItem)"
          >
            <img
              class="full-img-card-img"
              :src="$url + optionItem.image"
              alt=""
            />
            <h2 class="full-img-card-title">
              {{ optionItem.optionItemName }}
            </h2>
            <h2 class="full-img-card-price">
              $ {{ formatPrice(optionItem.unitPrice) }}
            </h2>
            <div
              v-if="itemOption.includes(optionItem.optionItemId)"
              style="background-color: #e0e0e0"
            >
              <v-icon style="font-size: 30px" color="black">
                mdi-checkbox-marked
              </v-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-btn-group">
        <v-btn
          class="btn text-h5"
          color="primary"
          dark
          @click="addToCart()"
          style="border-radius: 5px"
        >
          UPDATE CART
        </v-btn>
        <v-btn
          class="btn text-h5"
          color="secondary2"
          dark
          @click="cancelOrder()"
          style="border-radius: 5px; margin-left: 3%"
        >
          CANCEL
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "ViewProductComponent",
  props: {
    singleItem: {},
    addedItem: {},
    closeView: Function,
    cartCount: 0,
    cartIndex: 0,

    itemAddonProps: {},
    itemAddonNameProps: {},

    itemSizeProps: "",
    itemSizeNameProps: "",

    optionMainProps: {},
    itemOptionProps: {},
    itemOptionNameProps: {},

    productCostProps: "",
    instructionsProps: {},
    instructionsNameProps: {},
    additionalInstructionProps: "",
    instructionsFinalProps: "",
  },

  components: { Loading },
  data() {
    return {
      count: 1,

      isLoading: false,
      loadingSize: 50,
      loadingFullPage: true,
      loading: false,

      netCost: 0,
      discount: 0,
      gst: 0,
      netBill: 0,

      // singleItem: {},
      // addonItems: [],
      // sizes: [],
      // ingredients: [],

      singleOrder: {
        productId: "",
        itemName: "",
        unitPrice: "",
        quantity: "",
        cost: "",
        image: "",
        instructions: "",
        orderType: "",
        //sizeId: "",
        // addonItems: [],
        // addonItemsName: [],
        // ingredients: [],
        // ingredientsName: []
      },
      menuProduct: {},

      //productCost: null,
      // itemSize: "",
      // itemSizeName: "",
      productCost: this.productCostProps,
      itemSize: this.itemSizeProps,
      itemSizeName: this.itemSizeNameProps,
      itemSizePrice: "",

      // itemAddon: [],
      // itemAddonName: [],

      itemAddon: this.itemAddonProps,
      itemAddonName: this.itemAddonNameProps,

      // instructions: [],
      // instructionsName: [],
      // additionalInstruction: "",
      // instructionsFinal: "",

      instructions: this.instructionsProps,
      instructionsName: this.instructionsNameProps,
      additionalInstruction: this.additionalInstructionProps,
      instructionsFinal: this.instructionsFinalProps,

      // optionMain: [],
      // itemOption: [],
      // itemOptionName: [],

      optionMain: this.optionMainProps,
      itemOption: this.itemOptionProps,
      itemOptionName: this.itemOptionNameProps,

      itemOptionPrices: [],

      itemIngredient: [],
      itemIngredientName: [],
    };
  },

  created() {
    // console.log(instructions);
    // console.log(instructionsName);
    // console.log(additionalInstruction);
    // console.log(instructionsFinal);
    // this.itemAddon = this.addedItem.addonItems;
    //this.itemAddonName =  this.addedItem.addonItemsName;
    // console.log("Created");
    //  console.log("addedItem", addedItem);
  },

  computed: {
    ...mapState(["orderType"]),

    // itemAddon() {
    //   return this.addedItem.addonItems;
    // },
    // itemAddonName() {
    //   return this.addedItem.addonItemsName;
    // },
    // itemOption() {
    //   return this.addedItem.optionItems;
    // },
    // itemOptionName() {
    //   return this.addedItem.optionItemsName;
    // },
    // instructionsFinal() {
    //   return this.addedItem.instructions;
    // },
    // instructionsName() {
    //   var existIns = this.addedItem.instructions;
    //   var existIns = existIns.split(",");
    //   var existIns = existIns.slice(0, -1);
    //   return existIns;
    // },
    // additionalInstruction() {
    //   var existIns = this.addedItem.instructions;
    //   var existIns = existIns.split(",");

    //   return existIns[[existIns.length - 1]];
    // }
  },
  watch: {
    // productCost: function() {
    //   this.productCost = this.singleItem.retailPrice;
    // }
  },
  methods: {
    getDataFromApi() {
      // this.isLoading = true;
      //console.log("Sucess");

      this.$http.get(`/Menus/Products/${this.productId}`).then((response) => {
        console.log(response);
        this.menuProduct = response.data;
        this.sizes = this.menuProduct.productSizes;
        this.ingredients = this.menuProduct.productIngredients;

        for (var i = 0; i < this.menuProduct.productAddons.length; i++) {
          for (
            var j = 0;
            j < this.menuProduct.productAddons[i].addon.addonItems.length;
            j++
          ) {
            this.addonItems.push(
              this.menuProduct.productAddons[i].addon.addonItems[j]
            );
          }
        }

        console.log(this.addonItems);
        console.log("addons  :", this.menuProduct.productAddons.length);
        console.log("addon Items :", this.addonItems.length);

        this.isLoading = false;
      });
    },

    increaseCount() {
      //this.count = parseInt(this.count) + 1;
      // console.log(this.productCost);
      //      this.productCost = this.productCost ;
    },
    decreaseCount() {
      if (this.count > 1) {
        //this.count = parseInt(this.count) - 1;
        // this.productCost = this.productCost;
      }
    },

    addToCart() {
      console.log("productCost", this.productCost);
      //  console.log("addToCart btn clicked");
      console.log("index", this.cartIndex);

      // console.log("cartCount", this.cartCount);
      if (this.additionalInstruction && this.instructionsName.length > 0) {
        this.instructionsFinal =
          this.instructionsName.toString() + "," + this.additionalInstruction;
      } else if (this.additionalInstruction) {
        this.instructionsFinal = this.additionalInstruction;
      } else {
        this.instructionsFinal = this.instructionsName.toString();
      }

      if (this.singleItem.productSizes.length > 0 && this.itemSize == "") {
        alert("Size Reqired");
      } else {
        if (this.itemOption.length != this.singleItem.productOptions.length) {
          alert("Select Each One In Each Option");
        } else {
          // if (this.instructionsFinal == "") {
          //   alert("Instruction Required");
          // } else {
          // this.productCost = this.singleItem.retailPrice * this.count;
          if (this.productCost == null) {
            if (this.singleItem.isQuickMenu) {
              this.productCost = this.singleItem.discountPrice;
            } else {
              this.productCost = this.singleItem.retailPrice;
            }
          } else {
            this.productCost = this.productCost;
          }
          this.singleOrder = Object.assign({}, this.singleOrder, {
            productId: this.singleItem.productId,
            itemName: this.singleItem.productName,
            unitPrice: this.singleItem.retailPrice,
            quantity: this.count,
            cost: this.productCost,
            image: this.singleItem.itemPic,
            instructions: this.instructionsFinal,
            instructionsId: this.instructions,
            orderType: this.orderType,
            sizeId: this.itemSize,
            sizeName: this.itemSizeName,
            addonItems: this.itemAddon,
            addonItemsName: this.itemAddonName,
            ingredients: this.itemIngredient,
            ingredientsName: this.itemIngredientName,
            options: this.optionMain,
            optionItems: this.itemOption,
            optionItemsName: this.itemOptionName,
            cartCount: this.cartCount,
          });

          this.netCost = this.productCost;
          console.log("this.singleOrder", this.singleOrder);
          this.updateCart();
        }
      }
    },
    updateCart() {
      this.ADD_CART_DATA(this.singleOrder);
      this.ADD_NET_COST(this.netCost);
      //  console.log("this.cartCount ", this.cartCount);

      window.localStorage.setItem("editedCartItem", this.cartIndex);
      // this.$router.push("/cart");
      location.reload();
    },

    cancelOrder() {
      this.count = 1;
      this.productCost = null;
      this.itemSize = 0;
      this.itemAddon = [];
      this.itemAddonName = [];
      this.itemIngredient = [];
      this.itemIngredientName = [];
      this.itemSizePrice = "";
      this.closeView();

      console.log("cancelOrder btn clicked");
    },
    sizeClicked(item) {
      console.log("sizeClicked", item);

      if (this.itemSize == item.sizeId) {
        this.itemSize = "";
        this.itemSizeName = "";
        this.itemSizePrice = "";

        this.itemAddon = [];
        this.itemAddonName = [];
        this.optionMain = [];
        console.log("optionMain", this.optionMain);
        this.itemOption = [];
        this.itemOptionName = [];

        this.productCost = "";
      } else {
        this.itemSize = item.sizeId;
        this.itemSizeName = item.size.sizeName;
        this.itemSizePrice = item.price;
        this.productCost = item.price;

        this.itemAddon = [];
        this.itemAddonName = [];

        this.optionMain = [];
        console.log("optionMain", this.optionMain);
        this.itemOption = [];
        this.itemOptionName = [];
      }

      console.log("itemSize:", this.itemSize);
      console.log("itemSizeName:", this.itemSizeName);
      console.log("productCost", this.productCost);
      this.singleItem.retailPrice = this.itemSizePrice;
    },
    addOnItemClicked(item) {
      console.log("addOnItemClicked", item);
      console.log("productCost", this.productCost);
      if (this.singleItem.productSizes.length > 0 && !this.itemSize) {
        alert("Select Size");
      } else {
        if (this.itemAddon.includes(item.addonItemId)) {
          console.log("in");
          const index = this.itemAddon.indexOf(item.addonItemId);

          this.itemAddon.splice(index, 1);
          this.itemAddonName.splice(index, 1);

          this.productCost = this.productCost - item.unitPrice;
        } else {
          this.itemAddon.unshift(item.addonItemId);
          this.itemAddonName.unshift(item);
          console.log("productCost", this.productCost);

          if (this.productCost == null) {
            if (this.singleItem.isQuickMenu) {
              this.productCost = this.singleItem.discountPrice + item.unitPrice;
            } else {
              this.productCost = this.singleItem.retailPrice + item.unitPrice;
            }
          } else {
            this.productCost = this.productCost + item.unitPrice;
          }
        }

        console.log("itemAddon", this.itemAddon);
        console.log("itemAddonName", this.itemAddonName);
        console.log("productCost", this.productCost);

        let sum = 0;

        //productCost
      }
    },

    optionItemClicked(item) {
      console.log("optionItemClicked", item);
      if (this.singleItem.productSizes.length > 0 && !this.itemSize) {
        alert("Select Size");
      } else {
        if (this.optionMain.includes(item.optionId)) {
          //  const index = this.optionMain.indexOf(item.optionId);
          console.log("exist", item.optionId);
          // this.optionMain.splice(index, 1);

          if (this.itemOption.includes(item.optionItemId)) {
            console.log("in");
            const indexItem = this.itemOption.indexOf(item.optionItemId);
            const indexOption = this.optionMain.indexOf(item.optionId);
            console.log("exist", item.optionId);
            this.optionMain.splice(indexItem, 1);
            this.itemOption.splice(indexItem, 1);
            this.itemOptionName.splice(indexOption, 1);
            this.itemOptionPrices.splice(indexOption, 1);
            this.productCost = this.productCost - item.unitPrice;
            // this.singleItem.retailPrice =
            //   this.singleItem.retailPrice - item.unitPrice;
          }
        } else {
          this.optionMain.unshift(item.optionId);
          if (this.itemOption.includes(item.optionItemId)) {
            console.log("in");
            const indexItem = this.itemOption.indexOf(item.optionItemId);

            this.itemOption.splice(indexItem, 1);
            this.itemOptionName.splice(index, 1);
            // this.itemOptionPrices.splice(index, 1);
            this.productCost = this.productCost - item.unitPrice;
            // this.singleItem.retailPrice =
            //   this.singleItem.retailPrice - item.unitPrice;
          } else {
            this.itemOption.unshift(item.optionItemId);
            this.itemOptionName.unshift(item);
            //  this.itemOptionPrices.unshift(item.optionItemName);
            // this.productCost = this.productCost + item.unitPrice;
            if (this.productCost == null) {
              if (this.singleItem.isQuickMenu) {
                this.productCost =
                  this.singleItem.discountPrice + item.unitPrice;
              } else {
                this.productCost = this.singleItem.retailPrice + item.unitPrice;
              }
            } else {
              this.productCost = this.productCost + item.unitPrice;
            }

            // this.singleItem.retailPrice =
            //   this.singleItem.retailPrice + item.unitPrice;
          }
        }
        console.log("optionMain", this.optionMain);

        console.log("itemOption", this.itemOption);
        console.log("itemOptionName", this.itemOptionName);
        console.log("productCost", this.productCost);
      }
    },

    instructionClicked(item) {
      console.log("instructionClicked", item);
      if (this.singleItem.productSizes.length > 0 && !this.itemSize) {
        alert("Select Size");
      } else {
        if (this.instructions.includes(item.instructionId)) {
          console.log("in");
          const index = this.instructions.indexOf(item.instructionId);

          this.instructions.splice(index, 1);
          this.instructionsName.splice(index, 1);
        } else {
          this.instructions.unshift(item.instructionId);
          this.instructionsName.unshift(item.instruction.note);
        }
        console.log("instructions", this.instructions);
        console.log("instructionsName", this.instructionsName);
      }
    },
    addAdditionalIns() {
      console.log(this.additionalInstruction);
    },
    sideClicked(item) {
      console.log("sideClicked", item);
    },

    ...mapMutations(["ADD_CART_DATA"]),
    ...mapMutations(["UPDATE_NET_COST"]),
    ...mapMutations(["ADD_NET_COST"]),

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    removeDuplicate(array) {
      var uniqueArray = [];

      // Loop through array valuesarra
      if (array.length >= 0) {
        for (var i = 0; i < array.length; i++) {
          if (uniqueArray.indexOf(array[i]) === -1) {
            uniqueArray.push(array[i]);
          }
        }
        return uniqueArray;
      }
    },
    filterAddons(addonId) {
      // We can't find 'Taiwan' in nationalityArr
      return this.itemAddon.filter((n) => n === addonId).length === 0
        ? false
        : true; // false
    },
  },
};
</script>

<style scoped>
:root {
  --main-bg-color: #f9f9f9;
  --primary-color: #80c042;
  --secondary-color: #e0e0e0;
}

.main-bg {
  background-color: #f9f9f9;
}
.primary-bg {
  background-color: #80c042;
}
.secondary-bg {
  background-color: #e0e0e0;
}
.white-bg {
  background-color: #fff;
}

.primary-text {
  color: #80c042;
}
.secondary-text {
  color: #e0e0e0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: "Alegreya", serif; */
}

.h1 {
  font-size: 2em;
  font-weight: 500;
}
.h2 {
  font-size: 1.8em;
  font-weight: 500;
}
.h3 {
  font-size: 1.5em;
  font-weight: 500;
}
.h4 {
  font-size: 1.2em;
  font-weight: 500;
}
.h5 {
  font-size: 1em;
  font-weight: 500;
}
.h6 {
  font-size: 0.8em;
  font-weight: 500;
}

.w-400 {
  font-weight: 400;
}
.w-500 {
  font-weight: 500;
}
.w-600 {
  font-weight: 600;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
.center {
  justify-content: center;
}
.space-between {
  justify-content: space-between;
}

.btn {
  flex: auto;
  text-align: center;
  padding: 15px;
  margin: 3px;
  color: rgb(41, 18, 18);
  border-radius: 5px;
  cursor: pointer;
}
.btn:hover,
.btn:focus {
  opacity: 0.8;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.descClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  font-weight: bold;
}
.product-details {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  background-color: white;
  /* height: 580px; */
}
.product-img {
  margin-top: 10px;
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 35px;
}
.no-product-img {
  margin-top: 10px;
  width: 300px;
  height: 300px;
  border-radius: 35px;
}
.product-name-price {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 20px 10px;
  width: 375px;
}
.product-title {
  font-size: 1.8em;
  font-weight: 600;
  line-height: 1.2em;
  max-width: 60%;
  text-align: left;
}
.product-price {
  font-size: 1.8em;
  font-weight: 600;
  max-width: 40%;
  margin-top: -10px;
  text-align: right;
}
.product-description {
  padding: 0px 10px 0px 25px;
  max-width: 90%;
  font-size: 1em;
  font-weight: 500;
  margin-top: -15px;
  margin-bottom: -15px;
  height: 50px;

  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
}
.product-qty-price {
  width: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  margin-top: 10px;
  margin-bottom: -30px;
}
.product-qty {
  font-size: 1.5em;
  font-weight: 600;
  max-width: 25%;
}
.total-price {
  font-size: 1.5em;
  font-weight: 600;
  max-width: 75%;
}
.product-options {
  padding: 0 50px;
  margin-top: -30px;
  height: 600px;
  overflow-x: hidden;
  background-color: #f9f9f9;
}
.product-options::-webkit-scrollbar {
  /* display: none; */

  background: transparent; /* Optional: just make scrollbar invisible */
}
.options {
  margin-top: 50px;

  width: 375px;
}
.option-title {
  font-size: 1.5em;
  font-weight: 500;
}
.size-btn-group {
  /* width: 350px; */
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.toppings-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  cursor: pointer;

  /*overflow-x: auto;
  white-space: nowrap; */

  /* width: 315px; */
}
.item-option-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  cursor: pointer;

  /* overflow-x: auto;
  white-space: nowrap; */
  cursor: pointer;
}
.size-btn-group::-webkit-scrollbar,
.toppings-list::-webkit-scrollbar,
.item-option-list::-webkit-scrollbar {
  /* display: none; */

  background: transparent; /* Optional: just make scrollbar invisible */
}
.center-img-card {
  width: 120px;
  height: 180px;
  border-radius: 5px;
  margin: 2.5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.center-img-card:hover,
.center-img-card:focus {
  opacity: 0.8;
}
.center-img-card-title {
  font-size: 0.8em;
  font-weight: 600;
}
.center-img-card-img {
  width: 90px;
  border-radius: 5px;
}
.center-img-card-price {
  font-size: 1.2em;
  font-weight: 600;
}

.full-img-card {
  width: 120px;
  height: 150px;
  border-radius: 5px;
  margin: 2.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.full-img-card:hover,
.full-img-card:focus {
  opacity: 0.8;
}
.full-img-card-title {
  font-size: 0.8em;
  font-weight: 600;
}
.full-img-card-img {
  width: 120px;
  height: 95px;
  object-fit: cover;
  border-radius: 5px;
}
.full-img-card-price {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 7.5px;
}
.btn-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
}
.bottom-btn-group {
  display: none;
}
.cancel-btn-group {
  display: none;
}
@media (max-width: 1224px) {
  .product-qty-price,
  .btn-group {
    max-width: 380px;
  }
  .product-details {
    max-width: 400px;
  }
  .product-img {
    margin-top: 10px;
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 35px;
  }

  .no-product-img {
    margin-top: 10px;
    width: 300px;
    height: 300px;
    border-radius: 35px;
  }
  .product-options {
    max-width: 375px;
    margin-left: -20px;
  }

  .options {
    margin-top: 50px;

    max-width: 375px;
  }
  .toppings-list,
  .item-option-list,
  .bottom-btn-group,
  .cancel-btn-group,
  .size-btn-group {
    width: 310px;
  }
}
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  .product-title {
    font-size: 1.5em;
  }
  .product-price {
    font-size: 1.5em;
    text-align: right;
  }
  .product-qty {
    font-size: 1.3em;
  }
  .total-price {
    font-size: 1.3em;
  }
  .option-title {
    font-size: 1.2em;
  }
  .bottom-btn-group {
    max-width: 300px;
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0;
  }
  .cancel-btn-group {
    max-width: 400px;
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0;
  }
  .btn-group {
    display: none;
  }
  .product-options {
    max-width: 400px;
    margin-left: 0px;
    /* height: 600px; */
    overflow-x: auto;
    height: auto;
  }

  .product-details {
    margin-top: -80px;
    max-width: 400px;
    margin-left: -10px;
    height: 600px;
  }

  .product-img {
    width: 366px;
    margin-left: 9px;
    height: 370px;
    object-fit: cover;
  }
  .no-product-img {
    margin-left: 9px;
    height: 370px;
    width: 366px;
    height: 300px;
    border-radius: 35px;
  }
  .product-qty-price {
    width: 300px;
  }

  .product-qty {
    text-align: left;
    /* max-width: 70%; */
  }
  .total-price {
    text-align: left;
    /* max-width: 30%; */
  }

  .product-description {
    font-weight: 400;
    margin-top: 5px;
    width: 300px;
  }
  .toppings-list {
    width: 315px;
  }
  .item-option-list {
    width: 315px;
  }
  .size-btn-group {
    width: 300px;
  }
}

@media (max-width: 400px) {
  .size-btn-group,
  .toppings-list,
  .item-option-list,
  .bottom-btn-group,
  .cancel-btn-group {
    width: 280px;
  }

  .product-details {
    margin-top: -80px;
    max-width: 350px;
    margin-left: -15px;
    height: 600px;
  }

  .product-img {
    width: 327px;
    height: 375px;
    margin-left: 14px;
    object-fit: cover;
  }
  .no-product-img {
    width: 327px;
    height: 375px;
    margin-left: 14px;
    border-radius: 35px;
  }
  .product-name-price {
    width: 330px;
  }
  .product-options {
    max-width: 350px;
    margin-left: -20px;
    overflow-x: auto;
    /* height: 600px; */
    height: auto;
  }
}
</style>
