<template>
  <div class="review-container">
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <div class="products-section">
      <div class="page-title-group">
        <v-btn color="secondary" class="back-menu-text" @click="backToMenu">
          <v-icon color="black"> mdi-arrow-left </v-icon>
          <v-card-text class="black--text font-weight-bold"> Back </v-card-text>
        </v-btn>

        <!-- <h1 class="page-title">ORDER REVIEW</h1> -->
      </div>
      <div class="product-list">
        <div v-for="(product, i) in cartDataFinal" :key="i">
          <div>
            <h2 class="product-name">
              {{
                product.itemName +
                " (" +
                currencyFormat(product.unitPrice) +
                "/" +
                product.unitName +
                ")"
              }}

              <!-- <span class="" v-if="!isReview" @click="editProduct(product)">
                <v-icon class="text-h2" color="">
                  mdi-pencil-circle
                </v-icon>
              </span> -->
            </h2>
          </div>

          <div class="product">
            <img
              v-if="product.image"
              class="product-img"
              :src="$url + product.image"
              alt=""
            />
            <div class="product-img" v-if="!product.image">
              <v-icon class="text-h1"> mdi-image </v-icon>
              No Image
            </div>

            <!-- <div class="product-add-on-list">
              <h4
                v-if="
                  product.addonItemsName && product.addonItemsName.length > 0
                "
                @click="showAddons = !showAddons"
                class="product-sub-title mt-3 mb-3"
              >
                ADDONS
                <v-icon v-if="!showAddons" color="black">
                  mdi-arrow-down-drop-circle
                </v-icon>
                <v-icon v-if="showAddons" color="black">
                  mdi-arrow-up-drop-circle
                </v-icon>
              </h4>

              <div v-if="showAddons">
                <div
                  class="product-add-on"
                  v-for="addon in product.addonItemsName"
                  :key="addon.billProductAddonId"
                >
                  <h3 class="product-add-on-title">
                    {{
                      addon.addonItemName +
                      " (" +
                      currencyFormat(addon.unitPrice) +
                      ")"
                    }}
                  </h3>

                  <div
                    class="close-icon-btn"
                    v-if="!isReview"
                    @click="removeAddon(product, addon)"
                  >
                    <div style="margin-top: -6px">
                      <v-icon class="text-h4" color="">
                        mdi-close-circle
                      </v-icon>
                    </div>
                  </div>
                </div>
              </div>
              <h4
                v-if="
                  product.optionItemsName && product.optionItemsName.length > 0
                "
                @click="showOptions = !showOptions"
                class="product-sub-title mt-3 mb-3"
              >
                OPTIONS
                <v-icon v-if="!showOptions" color="black">
                  mdi-arrow-down-drop-circle
                </v-icon>
                <v-icon v-if="showOptions" color="black">
                  mdi-arrow-up-drop-circle
                </v-icon>
              </h4>
              <div v-if="showOptions">
                <div
                  class="product-add-on"
                  v-for="option in product.optionItemsName"
                  :key="option.billProductOptionId"
                >
                  <h3 class="product-add-on-title">
                    {{
                      option.optionItemName +
                      " (" +
                      currencyFormat(option.unitPrice) +
                      ")"
                    }}
                  </h3>
 
                </div>
              </div>
              <h4
                v-if="product.instructions"
                @click="showInstructions = !showInstructions"
                class="product-sub-title mt-3 mb-3"
              >
                INSTRUCTIONS
                <v-icon v-if="!showInstructions" color="black">
                  mdi-arrow-down-drop-circle
                </v-icon>
                <v-icon v-if="showInstructions" color="black">
                  mdi-arrow-up-drop-circle
                </v-icon>
              </h4>
              <div v-if="showInstructions">
                <div class="product-add-on">
                  <h3 class="product-add-on-title">
                    {{ product.instructions }}
                  </h3>
                </div>
              </div>
            </div> -->

            <div class="product-qty-section">
              <!-- <h3 class="product-qty-title">QTY -</h3> -->
              <div class="product-qty-value-changer">
                <!-- <div
                  class="increment-icon-btn"
                  v-if="!isReview"
                  @click="increaseQty(product)"
                >
                  <v-icon color="" class="">
                    mdi-plus-circle
                  </v-icon>
                </div> -->
                <h2 class="product-qty-value">
                  {{ product.quantity + " " + product.unitName }}
                </h2>
                <!-- <div
                  class="decrement-icon-btn"
                  v-if="!isReview"
                  @click="decreaseQty(product)"
                >
                  <v-icon color="" class="">
                    mdi-minus-circle
                  </v-icon>
                </div> -->
              </div>
            </div>
            <h2 v-if="!product.costEdited" class="product-price">
              {{ currencyFormat(product.cost) }}
            </h2>
            <h2 v-if="product.costEdited" class="product-price">
              {{ currencyFormat(product.costEdited) }}
            </h2>

            <div class="product-remove-icon" @click="removeProduct(product)">
              <v-icon class="text-h2" color=""> mdi-close-circle </v-icon>
            </div>

            <div class="mobile-qty-price-remove">
              <div class="mobile-product-qty-section">
                <!-- <h3 class="mobile-product-qty-title">QTY -</h3> -->
                <div class="mobile-product-qty-value-changer">
                  <!-- <div
                    class="mobile-decrement-icon-btn"
                    v-if="!isReview"
                    @click="decreaseQty(product)"
                  >
                    <v-icon color="" class="">
                      mdi-minus-circle
                    </v-icon>
                  </div> -->
                  <h2 class="mobile-product-qty-value">
                    {{ product.quantity + " " + product.unitName }}
                  </h2>
                  <!-- <div
                    class="mobile-increment-icon-btn"
                    v-if="!isReview"
                    @click="increaseQty(product)"
                  >
                    <v-icon color="" class="">
                      mdi-plus-circle
                    </v-icon>
                  </div> -->
                </div>
              </div>
              <h2 v-if="!product.costEdited" class="mobile-product-price">
                {{ currencyFormat(product.cost) }}
              </h2>
              <h2 v-if="product.costEdited" class="mobile-product-price">
                {{ currencyFormat(product.costEdited) }}
              </h2>
              <div
                class="mobile-product-remove-icon"
                @click="removeProduct(product)"
              >
                <v-icon class="text-h2" color=""> mdi-close-circle </v-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="payment-section">
      <div>
        <div>
          <v-col cols="12" style="margin-bottom: -10%">
            <button
              class="order-type-btn"
              v-if="orderTypeFinal == 1"
              @click="setOrderType(0)"
            >
              <v-icon class="font-weight-bold text-h3" color="black">
                mdi-food-takeout-box
              </v-icon>
              <span class="black--text font-weight-bold"> Pick Up</span>
            </button>

            <div class="order-type-btn-selected" v-if="orderTypeFinal == 0">
              <v-icon class="font-weight-bold text-h3" color="black">
                mdi-food-takeout-box
              </v-icon>
              <span class="black--text font-weight-bold"> Pick Up</span>
            </div>

            <button
              class="order-type-btn"
              v-if="orderTypeFinal == 0"
              @click="setOrderType(1)"
            >
              <v-icon class="font-weight-bold text-h3" color="black">
                mdi-bike-fast
              </v-icon>
              <span class="black--text font-weight-bold"> Delivery</span>
            </button>
            <div class="order-type-btn-selected" v-if="orderTypeFinal == 1">
              <v-icon class="font-weight-bold text-h3" color="black">
                mdi-bike-fast
              </v-icon>
              <span class="black--text font-weight-bold">Delivery</span>
            </div>
            <v-col v-if="this.orderTypeFinal == 1" cols="12" sm="12" md="12">
              <v-combobox
                v-model="deliveryChargeFinal"
                label="Delivery Cost"
                solo
                type="number"
                :items="deliveryPrices"
              ></v-combobox>
            </v-col>

            <v-combobox
              v-model="discountChargeFinal"
              label="Discount"
              item-text="text"
              item-value="value"
              :items="discounts"
            ></v-combobox>

            <v-text-field
              v-model="orderBill.extraCharges"
              label="Extra Charges"
              clearable
            />
          </v-col>
        </div>

        <div class="payment-list">
          <div class="payment-group">
            <h3 class="payment-name">SUB TOTAL</h3>
            <h3 class="payment-price">
              {{ currencyFormat(cartSubTotalFinal) }}
            </h3>
          </div>

          <div v-if="orderBill.extraCharges > 0" class="payment-group">
            <h3 class="payment-name">EXTRA CHARGE</h3>
            <h3 class="payment-price">
              {{ currencyFormat(orderBill.extraCharges) }}
            </h3>
          </div>

          <div v-if="orderTypeFinal == 1" class="payment-group">
            <h3 class="payment-name">DELIVERY</h3>
            <h3 class="payment-price">
              {{ currencyFormat(this.deliveryChargeFinal) }}
            </h3>
          </div>

          <div v-if="discountTotal" class="payment-group">
            <h3 class="payment-name">DISCOUNT</h3>
            <h3 class="payment-price">
              {{ currencyFormat(this.discountTotal) }}
            </h3>
          </div>

          <div class="payment-group">
            <h3 class="payment-name">TOTAL</h3>
            <h3 class="payment-price">
              {{ currencyFormat(cartNetCostComputed) }}
            </h3>
          </div>

          <!-- <div class="payment-group">
            <h3 class="payment-name">TOTAL Normal</h3>
            <h3 class="payment-price">
              {{ currencyFormat(cartNetCostFinal) }}
            </h3>
          </div> -->
        </div>

        <div>
          <v-col cols="12">
            <button
              @click="billNumber ? update() : customerInfo()"
              class="pay-btn black--text font-weight-bold"
            >
              <v-icon class="font-weight-bold text-h3 mr-2" color="black">
                mdi-store-check-outline
              </v-icon>
              <span class="black--text font-weight-bold text-uppercase">
                {{ billNumber ? "Update" : "Checkout" }}
              </span>
            </button>
            <button @click="emptyCart" class="cart-empty-btn">
              <v-icon class="font-weight-bold text-h3 mr-2" color="black">
                mdi-cart-remove
              </v-icon>
              <span class="black--text font-weight-bold text-uppercase"
                >Clear Cart</span
              >
            </button>
          </v-col>
        </div>
      </div>
    </div>
    <v-dialog v-model="placeOrderView" max-width="450px">
      <v-card>
        <v-col cols="12">
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="placeOrderView = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>

          <v-card-title>
            <span class="headline">Checkout</span>
          </v-card-title>
          <v-card-text>
            <v-container style="background-color: whitesmoke">
              <v-form ref="CustomerForm">
                <v-row>
                  <v-col
                    v-if="userRole != 'Customer'"
                    cols="12"
                    style="margin-bottom: -10%"
                  >
                    <v-autocomplete
                      v-model="customer"
                      :items="customers"
                      :loading="isLoading"
                      :search-input.sync="searchName"
                      hide-no-data
                      @change="setCustomer()"
                      label="Search Customer"
                      item-text="firstName"
                      item-value="customerId"
                      placeholder="Start typing"
                      prepend-icon="mdi-account-search"
                      return-object
                      clearable
                    />
                  </v-col>
                  <v-col cols="12" style="margin-bottom: -10%">
                    <v-text-field
                      v-model="orderBill.customerName"
                      label="Name"
                      clearable
                    />
                  </v-col>
                  <v-col cols="12" style="margin-bottom: -10%">
                    <v-text-field
                      v-model="orderBill.deliveryContactNo"
                      label="Phone No"
                      clearable
                    />
                  </v-col>
                  <v-col cols="12" style="margin-bottom: -10%">
                    <v-text-field
                      v-model="orderBill.deliveryAddress"
                      label="Address"
                      clearable
                    />
                  </v-col>

                  <v-col cols="12" style="">
                    <v-text-field
                      v-model="orderBill.deliveryPostCode"
                      label="Postal Code"
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-btn
            :style="{ left: '50%', transform: 'translateX(-50%)' }"
            color="primary"
            dark
            tile
            @click="getBillNo"
          >
            <v-card-text class="black--text font-weight-bold">
              Place the Order
            </v-card-text>
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmOrder" max-width="450px">
      <v-card>
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="confirmOrder = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>
        <v-card-title style="word-break: normal" class="headline">
          <span style="font-size: 20px; font-weight: bold">
            Are you confirm the order ? </span
          ><br />
          <span
            style="
              text-transform: uppercase;
              font-size: 12px;
              font-weight: bold;
              margin-top: 10px;
            "
          >
            You will be redirect to bill section and you can't add products
            anymore!
          </span>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="confirmOrder = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="customerInfo">
            Confirm
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmRemove" max-width="450px">
      <v-card>
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="confirmRemove = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>
        <v-card-title class="headline">
          <span style="font-size: 15px; font-weight: bold">
            Are you confirm to remove {{ removeItem.itemName }} ?
          </span>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="confirmRemove = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="confirmRemoveProduct">
            Confirm
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogViewProduct"
      persistent
      max-width="1020px"
      transition="slide-x-reverse-transition"
    >
      <v-card style="background-color: #f9f9f9">
        <editProductComponent
          :closeView="closeViewProduct"
          :singleItem="singleItem"
          :cartCount="cartDataFinal.length"
          :cartIndex="cartIndex"
          :addedItem="addedItemInfo"
          :instructionsNameProps="instructionsName"
          :additionalInstructionProps="additionalInstruction"
          :itemAddonProps="itemAddon"
          :itemAddonNameProps="itemAddonName"
          :itemSizeProps="itemSize"
          :itemSizeNameProps="itemSizeName"
          :optionMainProps="optionMain"
          :itemOptionProps="itemOption"
          :itemOptionNameProps="itemOptionName"
          :instructionsProps="instructions"
          :instructionsFinalProps="instructionsFinal"
          :productCostProps="productCost"
        />

        <!-- <ViewProductComponent
          :closeView="closeViewProduct"
          :singleItem="singleItem"
          :cartCount="cartDataFinal.length"
        /> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import editProductComponent from "./components/Product Review.vue";

import ViewProductComponent from "./components/Product Order.vue";
import store from "../store";

export default {
  name: "OrderReview",
  components: { Loading, ViewProductComponent, editProductComponent },
  data: () => ({
    orderTypes: [
      { value: 0, text: "Pick Up" },
      { value: 1, text: "Delivery" },
    ],
    orderTypeFinal: 0,

    customers: [],
    cartIndex: 0,

    singleItem: [],
    addedItemInfo: [],
    discountChargeFinal: 0,
    discounts: [
      { text: "0%", value: 0 },
      { text: "5%", value: 5 },
      { text: "10%", value: 10 },
      { text: "15%", value: 15 },
      { text: "20%", value: 20 },
    ],
    itemSize: "",
    itemSizeName: "",
    itemAddon: [],
    itemAddonName: [],
    optionMain: [],
    itemOption: [],
    itemOptionName: [],
    instructions: [],
    instructionsName: [],
    additionalInstruction: "",
    instructionsFinal: "",

    productCost: null,

    dialogViewProduct: false,

    isLoading: false,
    loadingSize: 50,
    loadingFullPage: true,
    loading: false,
    taxPercentage: 10,
    bill: {},

    cartDataFinal: [],
    cartNetCostFinal: 0,
    cartGstFinal: 0,

    orderTypeFinal: 0,
    orderTypeText: "",

    orderBill: {
      customerId: "",
      customerName: "",
      deliveryContactNo: "",
      deliveryAddress: "",
      deliveryPostCode: "",
      billTotal: "",
      extraCharges: "",
      cartSubTotalFinal: "",
      orderType: "",
      billNo: "",
      products: [],
    },
    bills: [],
    placeOrderView: false,

    showAddons: false,
    showOptions: false,
    showInstructions: false,
    confirmOrder: false,
    confirmRemove: false,
    removeItem: {},
    deliveryChargeFinal: 0,
    units: [],
    searchName: null,
    searchTimeout: null,
    customer: null,
    userRole: store.state.authUser.role,
    deliveryPrices: [],
    cartSubTotalFinal: 0,
    billNumber: 0,
    billHeaderId: 0,
  }),

  computed: {
    ...mapState(["cartData"]),
    ...mapState(["cartNetCost"]),
    ...mapState(["cartGst"]),
    ...mapState(["orderType"]),
    ...mapState(["storeBillInfo"]),

    cartNetCostComputed() {
      console.log(
        "cartNetCostComputedcartSubTotalFinal",
        this.cartSubTotalFinal
      );
      var extraCharges = this.orderBill.extraCharges;
      var deliveryChargeFinal = this.deliveryChargeFinal;
      var cartSubTotalFinal = this.cartSubTotalFinal;
      var cartDiscountTotalFinal = this.discountTotal;

      if (this.deliveryChargeFinal == "" || this.deliveryChargeFinal == null) {
        deliveryChargeFinal = 0;
      }
      if (
        this.orderBill.extraCharges == "" ||
        this.orderBill.extraCharges == null
      ) {
        extraCharges = 0;
      }

      if (this.discountTotal == "" || this.discountTotal == null) {
        cartDiscountTotalFinal = 0;
      }

      return (
        parseFloat(cartSubTotalFinal) +
        parseFloat(deliveryChargeFinal) +
        parseFloat(extraCharges) -
        parseFloat(cartDiscountTotalFinal)
      );
    },
    discountTotal() {
      let productDiscount = this.cartDataFinal.reduce((total, item) => {
        total += item.discountPrice * item.quantity;
        return total;
      }, 0);
      let totalDiscount =
        this.cartSubTotalFinal * (this.discountChargeFinal.value / 100);

      return (productDiscount || 0) + (totalDiscount || 0);
    },
    isReview() {
      return this.billNumber > 0 ? true : false;
    },
    subTotal() {
      return this.isReview ? this.bill.billTotalWithOutVat : 0;
    },
    tax() {
      return this.isReview
        ? this.bill.vatTotal
        : this.subTotal * (this.taxPercentage / 100);
    },

    extraCharges() {
      return this.orderBill.extraCharges;
    },
    resourceUrl() {
      return this.$http.defaults.baseURL.replace("/api", "");
    },
  },
  created() {
    if (this.$route.params.id) {
      this.billNumber = this.$route.params.id;
    }

    this.$http.get("settings/deliveryCharge").then((res) => {
      this.deliveryPrices = [];
      this.deliveryPrices.push(res.data);
    });

    console.log("userRole", this.userRole);
    if (store.state.authUser.role == "Customer") {
      this.getLoggedInCustomer();
    }
    this.ADD_MENU_NAME("Cart");
    // this.getCustomers();
    if (this.billNumber) {
      this.getData();
    } else {
      this.begin();
    }
  },
  watch: {
    searchName(val) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        if (val != null && val != "") {
          var pagination = {
            pageNumber: 1,
            pageSize: 10,
            search: val,
          };
          this.isLoading = true;
          this.$http
            .post("/Customers/Paginated", null, { params: pagination })
            .then((response) => {
              this.customers = response.data.data.items;
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response.status) {
                this.$toast.error("Error in getting Data", "Error", {
                  position: "topRight",
                });
              }
            });
        } else {
          this.customers = [];
        }
      }, 1500);
    },
  },

  methods: {
    async getLoggedInCustomer() {
      this.isLoading = true;

      await this.$http.get("customer").then((response) => {
        var customer = response.data.data;
        this.orderBill.customerName = customer.firstName;
        this.orderBill.deliveryContactNo = customer.telephone;
        this.orderBill.deliveryAddress =
          customer.address + ", " + customer.city + ", " + customer.state;
        this.orderBill.deliveryPostCode = customer.postcode;
        this.orderBill.deliveryBoyName =
          customer.firstName + ", " + customer.lastName;

        this.isLoading = false;
      });
    },
    async getCustomers() {
      var pagination = { pageNumber: 1, pageSize: 10 };
      this.isLoading = true;
      await this.$http
        .post("/Customers/Paginated", null, { params: pagination })
        .then((response) => {
          console.log(response.data.data);
          this.customers = response.data.data.items;
          console.log("this.customers", this.customers);
          //  this.customers = this.departments.reverse();
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },

    ...mapMutations(["ADD_ORDER_TYPE"]),
    setOrderType(value) {
      console.log("Worked");
      if (value == 1) {
        this.isLoading = true;

        this.$http.get(`Settings/DeliveryCharge`).then((response) => {
          this.deliveryChargeFinal = response.data;
          this.isLoading = false;
        });
      }
      this.deliveryChargeFinal = "";

      this.orderTypeFinal = value;

      //      this.ADD_ORDER_TYPE(this.orderTypeFinal);
    },

    setCustomer() {
      console.log("this.customer", this.customer);
      var customer = this.customer;
      if (customer == null) {
        this.orderBill.customerId = "";
        this.orderBill.customerName = "";
        this.orderBill.deliveryContactNo = "";
        this.orderBill.deliveryAddress = "";
        this.orderBill.deliveryPostCode = "";
      } else {
        // var customer = this.customers.find(
        //   val => val.customerId == this.orderBill.customerId
        // );
        this.orderBill.customerId = customer.customerId;
        this.orderBill.customerName =
          customer.firstName + " " + customer.lastName;

        this.orderBill.deliveryContactNo = customer.telephone;
        this.orderBill.deliveryAddress =
          customer.address + ", " + customer.city + ", " + customer.state;
        this.orderBill.deliveryPostCode = customer.postcode;
        this.orderBill.deliveryBoyName =
          customer.firstName + ", " + customer.lastName;
      }
    },

    begin() {
      if (localStorage.getItem("cartDataStore") !== null) {
        this.cartDataFinal = JSON.parse(
          window.localStorage.getItem("cartDataStore")
        );

        if (this.cartDataFinal && this.cartDataFinal.length > 0) {
          this.billNumber = this.cartDataFinal[0].billNo;
          this.billHeaderId = this.cartDataFinal[0].billHeaderId;

          if (this.billNumber && this.billHeaderId) {
            this.setOrderType(this.cartDataFinal[0].orderType);
            this.deliveryChargeFinal = this.cartDataFinal[0].deliveryCharge;
            this.orderBill.extraCharges = this.cartDataFinal[0].extraCharges;
          }
        }
        this.cartNetCostFinal = window.localStorage.getItem("cartNetCostStore");

        this.cartGstFinal = window.localStorage.getItem("cartGstStore");
        //  this.orderTypeFinal = window.localStorage.getItem("orderTypeStore");

        this.removeEdited();
      } else {
        this.cartDataFinal = this.cartData;

        //    this.orderTypeFinal = this.orderType;

        this.cartNetCostFinal = this.cartNetCost;
        this.cartGstFinal = this.cartGst;
        this.removeEdited();
      }
      this.cartSubTotalFinal = this.cartNetCostFinal;

      this.isLoading = false;
    },

    removeEdited() {
      if (localStorage.getItem("editedCartItem") !== null) {
        var itemIndex = window.localStorage.getItem("editedCartItem");
        console.log("itemIndex", itemIndex);
        const item = this.cartDataFinal[itemIndex];

        console.log("found", item);
        localStorage.removeItem("editedCartItem");
        console.log(this.cartNetCostFinal);
        this.cartDataFinal.splice(itemIndex, 1);

        this.UPDATE_CART_DATA(this.cartDataFinal);

        this.cartNetCostFinal =
          this.cartNetCostFinal - item.cost * item.quantity;

        this.cartGstFinal = (this.cartNetCostFinal * 10) / 100;

        this.UPDATE_NET_COST(this.cartNetCostFinal);
      }
    },
    ...mapMutations(["ADD_MENU_NAME"]),
    ...mapMutations(["ADD_CHECKOUT_LOGIN"]),

    ...mapMutations(["UPDATE_NET_COST"]),

    ...mapMutations(["UPDATE_CART_DATA"]),
    ...mapMutations(["ADD_BILL_NO"]),
    ...mapMutations(["ADD_NET_COST"]),
    ...mapMutations(["ADD_BILL_INFO"]),

    editProduct(item) {
      this.addedItemInfo = item;
      console.log("porduct : ", item);
      this.cartIndex = this.cartDataFinal.indexOf(item);
      console.log("this", this.cartIndex);

      this.isLoading = true;
      this.$http.get(`/Menus/Products/${item.productId}`).then((response) => {
        this.singleItem = response.data;
        //  console.log(this.singleItem);

        //   console.log(item);

        this.itemSize = item.sizeId;
        this.itemSizeName = item.sizeName;
        this.itemAddon = item.addonItems;
        this.itemAddonName = item.addonItemsName;
        this.optionMain = item.options;
        this.productCost = item.cost;
        this.itemOption = item.optionItems;
        this.itemOptionName = item.optionItemsName;

        this.instructionsFinal = item.instructions;

        this.instructions = item.instructionsId;
        console.log("this.instructions", this.instructions);

        var existIns = item.instructions;
        var existIns = existIns.split(",");

        if (item.instructionsId.length != existIns.length) {
          var addIns = existIns[[existIns.length - 1]];
          var existIns = existIns.slice(0, -1);
        }

        this.instructionsName = existIns;
        console.log("this.instructionsName", this.instructionsName);

        this.additionalInstruction = addIns;
        console.log("this.additionalInstruction", this.additionalInstruction);

        // :instructions="instructions"
        // :instructionsName="instructionsName"
        // :additionalInstruction="instructionsName"
        // :instructionsFinal="instructionsFinal"

        this.dialogViewProduct = true;

        this.isLoading = false;
        // this.removeProduct(item)
      });
    },

    closeViewProduct() {
      this.dialogViewProduct = false;
      this.isLoading = true;
      this.begin();
      //  this.isLoading = false;
    },

    getData() {
      this.$http
        .get(`BillHeader/BillNo/${this.billNumber}`)
        .then((response) => {
          if (response.data.success) {
            let bill = response.data.data;
            let products = bill.billProducts.map((x) => ({
              billHeaderId: bill.billHeaderId,
              billNo: bill.billNo,
              orderType: bill.orderType,
              deliveryCharge: bill.deliveryCharge,
              extraCharges: bill.extraCharges,
              billProductId: x.billProductId,
              productId: x.productId,
              itemName: x.productDescription,
              unitPrice: x.realRetailPrice,
              quantity:
                x.unitQty && x.unitQty > 0
                  ? x.quantity / x.unitQty
                  : x.quantity,
              cost:
                x.retailPrice *
                (x.unitQty && x.unitQty > 0
                  ? x.quantity / x.unitQty
                  : x.quantity),
              image: x.image,
              instructions: x.instructions,
              orderType: bill.orderType,
              customizedPrice: x.retailPrice,
              unitId: x.unitId,
              unitName: x.unitName,
              unitQty: x.unitQty,
              desc: "",
              discountPrice: 0.0,
              cartCount: x.productId,
            }));
            this.UPDATE_CART_DATA(products);
            this.UPDATE_NET_COST(
              products.reduce((total, item) => {
                total +=
                  (item.customizedPrice || item.unitPrice) * item.quantity;
                return total;
              }, 0)
            );

            this.begin();
          }
        });
    },
    removeAddon(product, addon) {
      let index = product.addonItemsName.findIndex(
        (x) => x.addonItemId == addon.addonItemId
      );

      product.cost = product.cost - product.addonItemsName[index].unitPrice;
      product.costEdited = product.cost * product.quantity;
      this.cartNetCostFinal =
        this.cartNetCostFinal -
        product.addonItemsName[index].unitPrice * product.quantity;

      product.addonItemsName.splice(index, 1);
      product.addonItems.splice(index, 1);

      this.UPDATE_CART_DATA(this.cartDataFinal);
      this.UPDATE_NET_COST(this.cartNetCostFinal);

      this.cartGstFinal = (this.cartNetCostFinal * 10) / 100;
    },

    removeOption(product, option) {
      let index = product.optionItemsName.findIndex(
        (x) => x.optionItemId == option.optionItemId
      );

      product.cost = product.cost - product.optionItemsName[index].unitPrice;
      product.costEdited = product.cost * product.quantity;
      this.cartNetCostFinal =
        this.cartNetCostFinal -
        product.optionItemsName[index].unitPrice * product.quantity;

      product.optionItemsName.splice(index, 1);
      product.optionItems.splice(index, 1);

      this.UPDATE_CART_DATA(this.cartDataFinal);
      this.UPDATE_NET_COST(this.cartNetCostFinal);

      this.cartGstFinal = (this.cartNetCostFinal * 10) / 100;
    },
    increaseQty(product) {
      product.quantity++;

      if (!product.costEdited) {
        product.costEdited = product.cost + product.cost;
      } else {
        product.costEdited = product.costEdited + product.cost;
      }

      this.cartNetCostFinal = parseFloat(this.cartNetCostFinal) + product.cost;
      this.cartGstFinal = (this.cartNetCostFinal * 10) / 100;
      this.UPDATE_NET_COST(this.cartNetCostFinal);
      this.UPDATE_CART_DATA(this.cartDataFinal);
    },
    decreaseQty(product) {
      if (product.quantity > 1) {
        product.quantity--;

        product.costEdited =
          product.costEdited - product.costEdited / (product.quantity + 1);

        this.cartNetCostFinal =
          parseFloat(this.cartNetCostFinal) - product.cost;

        this.cartGstFinal = (this.cartNetCostFinal * 10) / 100;
        this.UPDATE_NET_COST(this.cartNetCostFinal);
        this.UPDATE_CART_DATA(this.cartDataFinal);
      }
    },
    removeProduct(item) {
      this.removeItem = item;
      this.confirmRemove = true;

      console.log(this.removeItem);
    },
    confirmRemoveProduct() {
      this.confirmRemove = false;

      let index = this.cartDataFinal.findIndex(
        (x) => x.cartCount == this.removeItem.cartCount
      );

      this.cartDataFinal.splice(index, 1);

      this.UPDATE_CART_DATA(this.cartDataFinal);

      this.cartNetCostFinal =
        this.cartNetCostFinal -
        this.removeItem.unitPrice * this.removeItem.quantity;

      this.cartSubTotalFinal = this.cartNetCostFinal;

      console.log("this.cartSubTotalFinal", this.cartSubTotalFinal);

      this.cartGstFinal = (this.cartNetCostFinal * 10) / 100;

      this.UPDATE_NET_COST(this.cartNetCostFinal);
      console.log("this.orderTypeFinal", this.orderTypeFinal);
      this.deliveryChargeFinal = 0;

      if (this.orderTypeFinal == 0) {
        this.setOrderType(0);
      } else {
        this.setOrderType(1);
      }

      if (this.cartDataFinal.length == 0) {
        this.$router.push("/home");
      }
    },

    pay() {},

    backToMenu() {
      this.$router.push("/home");
    },
    currencyFormat(val) {
      return val ? "$" + parseFloat(val).toFixed(2) : "$0.00";
    },

    customerInfo() {
      if (store.getters["authUser/isLoggedIn"]) {
        //  console.log("Logged In");
        //         console.log("this.orderTypeFinal", this.orderTypeFinal);
        if (this.orderTypeFinal == null) {
          this.$toast.error("Select order type", "Error", {
            position: "topRight",
          });
        } else {
          this.confirmOrder = false;
          this.orderBill.billTotal = this.cartNetCostFinal;
          this.orderBill.orderType = this.orderTypeFinal;
          this.placeOrderView = true;
        }
      } else {
        this.ADD_CHECKOUT_LOGIN(true);
        this.$router.push("/login");
      }
    },
    getBillNo() {
      this.isLoading = true;
      this.$http.get("/BillHeader").then((response) => {
        this.bills = response.data.data;
        var lastBillNo = Math.max.apply(
          Math,
          this.bills.map(function (o) {
            return o.billNo;
          })
        );

        if (this.bills.length == 0) {
          this.billNo = 1;
        } else {
          this.billNo = lastBillNo + 1;
        }

        this.isLoading = false;
        this.setQty();
      });
      // this.storeBillInfo = "";
    },
    getUnit(id) {
      console.log("id", id);
      console.log("this.units", this.units);
      var unit = this.units.find((e) => e.unitID == id);

      if (unit) {
        console.log(" unit.unitName", unit.unitName);
        return unit.unitName;
      } else {
        return "";
      }
    },

    async setQty() {
      await this.$http.get("/Unites/").then((response) => {
        this.units = response.data.data;
      });

      console.log("this.cartDataFinal", this.cartDataFinal);

      for (var i = 0; i < this.cartDataFinal.length; i++) {
        this.cartDataFinal[i].orderType = this.orderTypeFinal;
        let unit = this.units.find(
          (x) => x.unitID === this.cartDataFinal[i].unitId
        );
        if (unit && unit.quantity && unit.quantity > 0) {
          this.cartDataFinal[i].quantity *= unit.quantity;
        }
      }

      this.placeOrder();
    },

    placeOrder() {
      for (var i = 0; i < this.cartDataFinal.length; i++) {
        delete this.cartDataFinal[i].addonItemsName;
        delete this.cartDataFinal[i].cartCount;
        delete this.cartDataFinal[i].cost;
        delete this.cartDataFinal[i].desc;
        delete this.cartDataFinal[i].image;
        delete this.cartDataFinal[i].ingredientsName;
        delete this.cartDataFinal[i].instructions;
        delete this.cartDataFinal[i].instructionsId;
        delete this.cartDataFinal[i].itemName;
        delete this.cartDataFinal[i].optionItemsName;
        delete this.cartDataFinal[i].options;
        delete this.cartDataFinal[i].sizeName;
        delete this.cartDataFinal[i].unitId;
        delete this.cartDataFinal[i].unitPrice;
      }

      this.orderBill.billNo = this.billNo;
      this.orderBill.deliveryCharge = this.deliveryChargeFinal;
      this.orderBill.additionalDiscount = this.discountChargeFinal.value;
      if (this.orderBill.extraCharges == "") {
        this.orderBill.extraCharges = 0;
      }
      this.orderBill.products = this.cartDataFinal;

      this.ADD_BILL_NO(this.billNo);

      this.$http
        .post("/Order", this.orderBill)
        .then((response) => {
          console.log("/Order", response);
          if (response.data.success === true) {
            this.isLoading = false;
            this.ADD_BILL_INFO(response.data.data);
            this.$toast.success("Order added successfully.", "Success", {
              position: "topRight",
            });

            this.cartData.splice(0, this.cartData.length);
            this.UPDATE_NET_COST(0);
            localStorage.removeItem("cartDataStore");

            //this.$router.push("/bill");
            this.placeOrderView = false;
            if (this.userRole == "Customer") {
              this.$router.push("/cart/bill/" + this.billNo);
            } else {
              this.$router.push("/admin/admin-bills/" + this.billNo);
            }
          } else {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.isLoading = false;
            this.$toast.error("Adding Process Failed", "Error", {
              position: "topRight",
            });
          }
        });
    },
    emptyCart() {
      this.cartData.splice(0, this.cartData.length);
      this.UPDATE_NET_COST(0);
      //this.ADD_NET_COST(-this.cartNetCost);

      // window.localStorage.clear();
      localStorage.removeItem("cartDataStore");
      this.$router.push("/home");
    },
    update() {
      let products = this.cartDataFinal
        .filter((x) => !x.hasOwnProperty("billHeaderId"))
        .map((x) => ({
          productId: x.productId,
          customizedPrice: x.customizedPrice || 0,
          customizedDescription: x.customizedDescription || null,
          quantity:
            x.unitQty && x.unitQty > 0 ? x.quantity * x.unitQty : x.quantity,
          instructions: x.instructions,
          orderType: this.orderTypeFinal,
          sizeId: x.sizeId,
          addonItems: x.addonItems,
          ingredients: x.ingredients,
          optionItems: x.optionItems,
        }));
      this.$http.post("/Order/Products", {
        billHeaderId: this.billHeaderId,
        products: products,
      });
      this.$http.get(`/BillHeader/${this.billHeaderId}`).then((res) => {
        let data = res.data.data;
        data.billProducts = [];
        data.status = data.orderStatus;
        data.deliverCharge = this.deliveryChargeFinal;
        data.extraCharges = this.orderBill.extraCharges;
        data.orderType = this.orderTypeFinal;
        this.$http.put(`/BillHeader/${this.billHeaderId}`, data).then((res) => {
          this.$toast.success("Order updated successfully.", "Success", {
            position: "topRight",
          });

          localStorage.removeItem("cartDataStore");

          if (this.userRole == "Customer") {
            this.$router.push("/cart/bill/" + this.billNumber);
          } else {
            this.$router.push("/admin/admin-bills/" + this.billNumber);
          }
        });
      });
    },
  },
};
</script>

<style scoped>
:root {
  --main-bg-color: #f9f9f9;
  --primary-color: #80c042;
  --secondary-color: #e0e0e0;
}

.main-bg {
  background-color: var(--main-bg-color);
}
.primary-bg {
  background-color: var(--primary-color);
}
.secondary-bg {
  background-color: var(--secondary-color);
}
.white-bg {
  background-color: #fff;
}

.primary-text {
  color: var(--primary-color);
}
.secondary-text {
  color: var(--secondary-color);
}

.h1 {
  font-size: 2em;
  font-weight: 500;
}
.h2 {
  font-size: 1.8em;
  font-weight: 500;
}
.h3 {
  font-size: 1.5em;
  font-weight: 500;
}
.h4 {
  font-size: 1.2em;
  font-weight: 500;
}
.h5 {
  font-size: 1em;
  font-weight: 500;
}
.h6 {
  font-size: 0.8em;
  font-weight: 500;
}

.w-400 {
  font-weight: 400;
}
.w-500 {
  font-weight: 500;
}
.w-600 {
  font-weight: 600;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
.center {
  justify-content: center;
}
.space-between {
  justify-content: space-between;
}

.btn {
  flex: auto;
  text-align: center;
  padding: 15px;
  margin: 3px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.btn:hover,
.btn:focus {
  opacity: 0.8;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.review-container {
  display: flex;
  flex-wrap: wrap;
}
.products-section {
  width: 50%;
  border-radius: 10px;
  background-color: var(--main-bg-color);
}
.page-title-group {
  display: flex;
  align-items: center;
}
.page-title {
  font-size: 2em;
  font-weight: 500;
  color: #75787e;
  padding: 10px 25px;
}
.mobile-back-menu-icon {
  display: none;
}
.product-list {
  max-height: 550px;

  margin-top: 10px;
  overflow-y: auto;
  white-space: nowrap;
}
.product-list::-webkit-scrollbar {
  display: none;
  background: transparent; /* Optional: just make scrollbar invisible */
}
.product {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  background: #fff;
  margin: 10px 15px;
  border-radius: 0px 0px 10px 10px;
}
.product-img {
  width: 150px;
  height: 100px;
  object-fit: cover;
  border-radius: 0 25px 0 25px;
}
.product-add-on-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 20px;
  max-width: 35%;
}
.product-name {
  padding: 15px 25px;
  background: #fff;
  margin: 10px 15px;
  margin-bottom: -25px;
  border-radius: 10px 10px 0px 0px;
  font-size: 1.2em;
  font-weight: 500;
}
.product-sub-title {
  cursor: pointer;
}
.product-add-on {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.product-add-on-title {
  font-size: 0.8em;
  font-weight: 600;
  padding: 0 5px;
  margin: 5px 0;
}
.close-icon-btn {
  background: #ffffff;
  border-radius: 25px;
  padding: 0 2.5px;
  height: 18px;
  font-size: 1.2em;
  font-weight: 500;
  cursor: pointer;
}
.close-icon-btn:hover,
.close-icon-btn:focus {
  opacity: 0.8;
}
.mobile-qty-price-remove {
  display: none;
}
.product-qty-section {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
.product-qty-title {
  font-size: 1em;
  font-weight: 600;
}
.product-qty-value-changer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
}
.increment-icon-btn {
  font-size: 1em;
  font-weight: 600;
  color: #6a6c70;
  cursor: pointer;
  margin-bottom: 5px;
}
.increment-icon-btn:hover,
.increment-icon-btn:focus {
  opacity: 0.8;
}
.product-qty-value {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--primary-color);
  margin: -5px 0 -5px;
}
.decrement-icon-btn {
  font-size: 1em;
  font-weight: 600;
  color: #6a6c70;
  cursor: pointer;
}
.decrement-icon-btn:hover,
.decrement-icon-btn:focus {
  opacity: 0.8;
}
.product-price {
  font-size: 1.3em;
  font-weight: 600;
  margin-left: 20px;
}
.product-remove-icon {
  background-color: #ffffff;
  border-radius: 10%;
  font-size: 1.8em;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
}
.product-remove-icon:hover,
.product-remove-icon:focus {
  opacity: 0.8;
}
.payment-section {
  border-radius: 10px;
  background-color: #f9f9f9;
  width: 50%;
  margin-top: 0%;
  padding: 0 25px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}
.back-to-menu-btn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1em;
  font-weight: 600;
  color: #adadad;
  cursor: pointer;
}
.back-to-menu-btn:hover,
.back-to-menu-btn:active {
  opacity: 0.8;
}
.back-menu-text {
  /* padding: 0 15px; */
  margin-top: 1.5px;
  margin-left: 15px;
}
.payment-list {
  width: 100%;
  padding: 10px;
  margin-top: 25px;
}
.payment-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.payment-name {
  font-size: 1.2em;
  font-weight: 500;
  color: #535457;
}
.payment-price {
  font-size: 1.3em;
  font-weight: 500;
  color: #535457;
}
.total-payment-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 25px 35px 0;
}
.payment-total {
  font-size: 1.8em;
  font-weight: 500;
  color: #535457;
}
.payment-total-price {
  font-size: 1.8em;
  font-weight: 500;
}

.ok-btn {
  background-color: #30b72a;
  text-align: center;
  height: 60%;
  width: 100%;
  font-size: 1em;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.pay-btn {
  background-color: #30b72a;
  text-align: center;
  padding: 7.5px;
  margin-bottom: 15px;
  font-size: 1em;
  width: 100%;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.pay-btn:hover,
.pay-btn:focus {
  background-color: #30b72a;
  opacity: 0.8;
}

.cart-empty-btn {
  background-color: #f8a229;
  color: black;
  text-align: center;
  padding: 7.5px;
  margin-bottom: 15px;
  font-size: 1em;
  width: 100%;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.cart-empty-btn:hover,
.cart-empty-btn:focus {
  background-color: #f8a229;
  opacity: 0.8;
}

.order-type-btn {
  background-color: #ffffff;

  text-align: center;
  padding: 7.5px;
  margin-bottom: 15px;
  font-size: 1em;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.order-type-btn-selected {
  background-color: #30b72a;

  text-align: center;
  padding: 7.5px;
  margin-bottom: 15px;
  font-size: 1em;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.order-type-btn:hover,
.order-type-btn:focus {
  background-color: #f8a229;
  opacity: 0.8;
}

.coupon-btn {
  font-size: 1.3em;
  font-weight: 500;
  color: #535457;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.coupon-btn:hover,
.coupon-btn:active {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    min-height: auto;
  }
  .page-title-group {
    margin-left: 10px;
  }
  .page-title {
    font-size: 1.5em;
  }
  .products-section {
    width: 100%;
  }
  .payment-section {
    width: 100%;
    padding: 25px;
  }
  .back-to-menu-btn {
    display: none;
  }
  .mobile-back-menu-icon {
    display: block;
    font-size: 2em;
    margin-top: -3.5px;
    /* padding-left: 15px; */
    color: #75787e;
    cursor: pointer;
  }
  .mobile-back-menu-icon:hover,
  .mobile-back-menu-icon:active {
    opacity: 0.8;
  }
  .product {
    flex-direction: column;
  }
  .product-img {
    max-width: 100%;
  }
  .product-add-on-list {
    max-width: 100%;
    margin: 15px 0;
  }
  .product-name {
    text-align: center;
    font-size: 1.4em;
  }
  .product-add-on {
    padding: 7.5px 0;
  }
  .product-add-on-title {
    font-size: 1em;
    font-weight: 500;
  }
  .close-icon-btn {
    font-size: 1.5em;
  }
  .product-qty-section {
    display: none;
  }
  .product-price {
    display: none;
  }
  .product-remove-icon {
    display: none;
  }
  .mobile-qty-price-remove {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mobile-product-qty-section {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }
  .mobile-product-qty-title {
    font-size: 1em;
    font-weight: 600;
    margin-right: 10px;
  }
  .mobile-product-qty-value-changer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  .mobile-increment-icon-btn {
    font-size: 1.5em;
    font-weight: 600;
    color: #6a6c70;
    cursor: pointer;
  }
  .mobile-increment-icon-btn:hover,
  .mobile-increment-icon-btn:focus {
    opacity: 0.8;
  }
  .mobile-product-qty-value {
    font-size: 1.2em;
    font-weight: 600;
    color: var(--primary-color);
    margin: -5px 10px -5px;
  }
  .mobile-decrement-icon-btn {
    font-size: 1.5em;
    font-weight: 600;
    color: #6a6c70;
    cursor: pointer;
  }
  .mobile-decrement-icon-btn:hover,
  .mobile-decrement-icon-btn:focus {
    opacity: 0.8;
  }
  .mobile-product-price {
    font-size: 1.3em;
    font-weight: 600;
    margin-left: 20px;
  }
  .mobile-product-remove-icon {
    padding: 0px 12px 2px 12px;
    background-color: #ffffff;
    border-radius: 50%;
    font-size: 1.8em;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    margin-left: 20px;
  }
}
</style>
